import React from 'react'
import "./Header.css"

function Header() {
  return (
    <div>
        <h1>Chinhnam Nguyen</h1>
        <h2>Software Engineer</h2>
    </div>
  )
}

export default Header