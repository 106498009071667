import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ExperienceBox from '../components/ExperienceBox/ExperienceBox'
import "./WorkExperience.css"

const WorkExperience = () => {
  return (
    <div>
        <Header />
        <Navbar />
        <h3>Work Experience</h3>
        <p className='ExperienceIntro'>
            Welcome to my table of contents for my current work experience! Here, you'll be able to find places and positions I've worked/am working, as well as what I worked on and took away from the experience. Click into each position to learn more!
            <br /><br />
            I have accepted a full-time return offer to work as a software engineer for Mastercard in New York City!
            <br /><br />
        </p>
        <hr className='ExperienceSplit'/>
        <h3>Current:</h3>
        <ExperienceBox title="Software Engineer" company="Mastercard" image="Images/Mastercard.png" date="August 2024 - Present" team="Krypton" location="New York City, NY"
        content="Will be updated periodically throughout my experience!"/>
        <ExperienceBox title="Lead Software Engineer" company="TerrapinWorks" image="Images/TerrapinWorks.png" date="January 2022 - Present" team="Software Team" location="College Park, MD"
        content="Leading a team focused on the development of a order management app that allows customers to create orders for specific machines/labs.///Order management app provides lab technicians and admins with important statistics as well as ease-of-access towards inspecting orders and current lab situations.///Developed a Flask/SQLAlchemy web application to allow trained users to access thousands of 3D printers and machinery on campus.///Added backend routing and databases as well as frontend components using React.///Utilizes UMD’s API to access user credentials in order to check permissions and streamline the user experience.///Developed a system of relational databases of machines, users, and user and lab permissions to efficiently store data."/>

        <br />
        
        <h3>Past:</h3>
        <ExperienceBox title="Software Engineer Intern" company="Mastercard" image="Images/Mastercard.png" date="June 2023 - August 2023" team="Krypton" location="New York City, NY"
        content="Developed a Next.js dashboard that pulls team commit data from Bitbucket and Rally API and exposes hidden data such as most targeted repositories, types of commits being made, as well as commit messages and times.///Developed a dependencies tracker that shows the most out of date dependencies across all repos using the npm API.///Developed on Mastercard’s client-facing checkout initiator on team Krypton, responsible for SRCi."/>
        <ExperienceBox title="Undergraduate Teaching Assistant" company="University of Maryland" image="Images/UMD.png" date="January 2023 - May 2023" team="CMSC426: Computer Vision" location="College Park, MD"
        content="Graded weekly students' Google Colab homeworks as well as responded to questions in Piazza.///Hosted weekly office hours in order to work one-on-one with students."/>
        <ExperienceBox title="Software Engineer Intern" company="Mastercard" image="Images/Mastercard.png" date="June 2022 - August 2022" team="Data Ingest Mapping (DIM)" location="Arlington, VA"
        content="Worked on the Data Ingest Mapping team, implementing data model and code changes to the DSM web platform to streamline the process of creating tables and adding clients while accommodating filters.///Implemented changes to the backend database design (C#) and corresponding frontend components (React/Redux/JSX) of the platform, such as editing pages as well as pushing DbUp scripts to edit existing databases."/>
        <ExperienceBox title="Undergraduate Research Assistant" company="University of Maryland" image="Images/UMD.png" date="Jan. 2022 - Sept. 2022" team="Robotics Algorithms & Autonomous Systems (RAAS) Lab" location="College Park, MD"
        content="Assistant researcher to PhD candidate Vishnu Sharma, advised by Professor Pratap Tokekar, researching the use of computer vision to aid in movement-pathing for robots. View here: https://arxiv.org/pdf/2104.11709.pdf///Used AI2-THOR to model physical scenes and test depth-perception using point clouds and machine learning."/>
        <ExperienceBox title="Database Engineer Intern" company="Mastercard" image="Images/Mastercard.png" date="June 2021 - August 2021" team="Big Data" location="O'Fallon, MO"
        content="Worked with personal deployments of Hadoop clusters and Cloudera Manager API to collect cluster data.///Wrote a Python script to automate the scraping of the API to collect metrics that could be formatted and sent to customers or execs at their request."/>
        <ExperienceBox title="Front Desk Employee" company="GMH Enterprises" image="Images/Tennis.png" date="June 2017 - Dec. 2020" team="Front Desk Staff" location="Bethesda, MD"
        content="Worked with tennis club members and tracked sign in/sign out.///Automated the sign-in process by creating a web page where members can sign in on their phone to avoid physical contact (COVID precaution)."/>

        <br />
        <Footer />
    </div>
  )
}

export default WorkExperience