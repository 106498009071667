import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import EmailForm from "../components/EmailForm/EmailForm"
import "./Contact.css"

const Contact = () => {
  return (
    <div>
      <Header />
      <Navbar />
      
      <h3>Contact Me</h3>
      <p>Feel free to connect with me on any platform!</p>
      <hr className='ContactSplit'/>

      <EmailForm />

      <Footer />
    </div>
  )
}

export default Contact