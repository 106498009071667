import React from "react";
import { Route, Routes } from "react-router-dom";
import "./index.css"
import Home from "./routes/Home"
import Resume from "./routes/Resume"
import Projects from "./routes/Projects";
import Essays from "./routes/Essays";
import Contact from "./routes/Contact";
import WorkExperience from "./routes/WorkExperience";
import InTheMoodForLove from "./components/Essays/InTheMoodForLove/InTheMoodForLove";
import EEAAO from "./components/Essays/EEAAO/EEAAO"
import ParisTexas from "./components/Essays/ParisTexas/ParisTexas";
import Aftersun from "./components/Essays/Aftersun/Aftersun";
import { Helmet } from "react-helmet";

import "./components/FontawesomeIcons"

function App() {
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Chinhnam Nguyen</title>
          <link rel="canonical" href="http://chinhnam.dev" />
          <meta name="description" content="Chinhnam's Personal Website" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/workexperience" element={<WorkExperience />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/essays" element={<Essays />} />
        <Route exact path="/essays/aftersun-what-we-make-of-nostalgia" element={<Aftersun />} />
        <Route exact path="/essays/in-the-mood-for-love-transcends-romance-genre" element={<InTheMoodForLove />} />
        <Route exact path="/essays/everything-everywhere-all-at-once-gender-norms" element={<EEAAO />} />
        <Route exact path="/essays/paris-texas-desire-to-be-somewhere-else" element={<ParisTexas />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/essays" element={<Essays />} />
      </Routes>
    </div>
  );
}

export default App;
