import React from 'react'
import "./Footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faInstagramSquare, faLinkedin, faSpotify, faTwitter } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <div>
      <hr />
      <div class="botnav">
          <a href="https://www.instagram.com/ch1nhnam/" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} size="2xl" /></a>
          <a href="https://twitter.com/ch1nhnam" target="_blank"><FontAwesomeIcon icon={faTwitter} size="2xl" /></a>
          <a href="https://github.com/chinhnam-n" target="_blank"><FontAwesomeIcon icon={faGithub} size="2xl" /></a>
          <a href="https://www.linkedin.com/in/chinhnam/" target="_blank"><FontAwesomeIcon icon={faLinkedin} size="2xl" /></a>
          <a href="https://letterboxd.com/chinhnam/" target="_blank"><FontAwesomeIcon icon="film" size="2xl" /></a>
          <a href="https://open.spotify.com/user/fdp3qk9t22xdkol13z9sy55c6?si=d92237777e8447e0" target="_blank"><FontAwesomeIcon icon={faSpotify} size="2xl" /></a>
          <h4 class="trademark">Chinhnam Nguyen 2024</h4>
      </div>
    </div>
  )
}

export default Footer