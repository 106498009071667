import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import "./Essays.css"

const Essays = () => {
  return (
    <div>
      <Header />
      <Navbar />

      <h3>Film Essays</h3>
      <p>
        Welcome to my essay showcase. These consist of works I've written for classes as well as out of pure interest.
        <br /><br />
        Please contact me with any comments or criticisms! I'm always interested in talking about film and improving my writing.
        <br /><br />
      </p>
      <hr className='EssaysSplit'/>

      <div class="EssaysRow">
        <a href='/essays/aftersun-what-we-make-of-nostalgia' className='RowLink'>
          <img src="/Images/aftersun.png" alt="Aftersun: What We Make of Nostalgia" className='RowImg'/>
          <div className='HoverText'>
              <p className='HoverInnerText'>
                <i>Aftersun</i>: What We Make of Nostalgia
              </p>
          </div>
        </a>

        <a href='/essays/paris-texas-desire-to-be-somewhere-else' className='RowLink'>
          <img src="/Images/ParisTexas.jpeg" alt="Paris, Texas and the Desire to be Somewhere Else" className='RowImg'/>
          <div className='HoverText'>
              <p className='HoverInnerText'>
                <i>Paris, Texas</i> and the Desire to be Somewhere Else
              </p>
          </div>
        </a>
      </div>

      <div class="EssaysRow">
        <a href='/essays/in-the-mood-for-love-transcends-romance-genre' className='RowLink'>
          <img src="/Images/InTheMoodForLove.png" alt="How 'In the Mood for Love' Transcends the Romance Genre" className='RowImg'/>
          <div className='HoverText'>
              <p className='HoverInnerText'>
                How <i>In The Mood For Love</i> Transcends the Romance Genre
              </p>
          </div>
        </a>

        <a href='/essays/everything-everywhere-all-at-once-gender-norms' className='RowLink'>
          <img src="/Images/EverythingEverywhereAllAtOnce.png" alt="Forest" className='RowImg'/>
          <div className='HoverText'>
              <p className='HoverInnerText'>
                Challenging Gender Norms: How <i>Everything Everywhere All At Once</i> Inspires the Courage to Love
              </p>
          </div>
        </a>
      </div>

      <br></br>
      <Footer />
    </div>
  )
}

export default Essays