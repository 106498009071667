import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import "./Home.css"

const Home = () => {
  return (
    <div>
      <Header />
      <Navbar />

      <h3>Welcome!</h3>
      <p>
        My name is Chinhnam and I'm a senior at the University of Maryland studying Computer Science and Statistics. I am
        passionate about applying technology to pressing, real-world problems.
        <br /><br />
        Right now I am working part-time at <a href="https://terrapinworks.umd.edu/" target="_blank">TerrapinWorks</a> on the software team, developing a web app to manage thousands of student-worked 3D printers and machines
        in labs all over campus. I have also accepted a full-time offer at <a href="https://www.mastercard.us/en-us.html" target="_blank">Mastercard</a> in New York City! You can find information for each of these experiences on their respective pages.
        <br /><br />
        I also love films and have included some of my essays here. Take a look and let me know what you think!
        <br /><br />
        Lastly, feel free to <a href = "mailto: cn.2149@gmail.com">email</a> or connect with me on any platform!
        <br /><br />
      </p>

      <h3>Some Technologies I've Worked With:</h3>
      <div class="row">
        <img src="/Images/Python.png" alt="Python3" />
        <img src="/Images/Java.svg" alt="Java" />
        <img src="/Images/C++.png" alt="C++" />
        <img src="/Images/JavaScript.png" alt="JavaScript" />
        <img src="/Images/HTML.png" alt="HTML" />
        <img src="/Images/CSS.png" alt="CSS" />
        <img src="/Images/sql.png" alt="SQL" />
      </div>
      <div class="row">
        <img src="/Images/react.png" alt="ReactJS" />
        <img src="/Images/redux.png" alt="Redux" />
        <img src="/Images/flask.png" alt="Flask" />
        <img src="/Images/sqlalchemy.jpeg" alt="SQLAlchemy" />
      </div>
      <br /><br />

      <Footer />
    </div>
  )
}

export default Home