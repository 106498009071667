import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import "./ProjectBox.css"

const ProjectBox = (props) => {
    const [open, setOpen] = useState(false)

    let imageItems = props.images.split("||")
    const listImages = imageItems.map((image) =>
        <img src={image} className='TechImg' />
    );

    let items = props.content.split("///")
    const listItems = items.map((item) =>
        <li>{item}</li>
    );

    return (
        <div>
            {/*********** Header Box **************/}
            <button type="button" onClick={() => setOpen(!open)} className='ProjectHeader'>
            {open? <div className='Arrow'><FontAwesomeIcon icon={faCaretDown} size="xl" /></div> : <div className='Arrow'><FontAwesomeIcon icon={faCaretRight} size="xl" /></div>}
                <div className='ProjectHeaderText'>
                    <div className='TitleType'>
                        <h3 className='ProjectBoxTitle'>{props.title}</h3>
                        <i className='ProjectBoxType'>{props.type}</i>
                    </div>
                    <i className='ProjectDate'>{props.date}</i>
                    <div className='TechImagesBox'>{listImages}</div>
                </div>
            </button>

            {/************** Work Info ******************/}
            {open &&
            <div className='ProjectContentBox'>
                <a href={props.link} className='ProjectContentLink' target='_blank'>{props.link}</a>
                <ul className='ProjectContentText'>{listItems}</ul>
            </div>}
        </div>
    )
}

export default ProjectBox