import React from "react"
import "./Navbar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"

const Navbar = () => {
    return (
        <div>
            <div class="topnav">
                <Link to="/"><FontAwesomeIcon icon="house" /> Home</Link>
                <Link to="/workexperience"><FontAwesomeIcon icon="briefcase" /> Work</Link>
                <Link to="/projects"><FontAwesomeIcon icon="bars-progress" /> Projects</Link>
                <Link to="/essays"><FontAwesomeIcon icon="file-video" /> Essays</Link>
                <Link to="/contact" class="split"><FontAwesomeIcon icon="envelope" /> Contact</Link>
                <Link to="/resume" class="split"><FontAwesomeIcon icon="file" /> Resume</Link>
            </div>
            <hr></hr>
        </div>
    )
}

export default Navbar