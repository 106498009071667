import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ProjectBox from '../components/ProjectBox/ProjectBox'
import "./Projects.css"

const Projects = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <h3>
        Personal Projects
      </h3>
      <p>
        Welcome to my projects showcase! Here, you can find all of my personal projects (shown in chronological order) and when I completed them. With each project, I've included a brief description as well as links to any deliverables and what I took away from the experience.
        <br /><br />
      </p>
      <hr className='ProjectsSplit'/>
      
      <ProjectBox title='chinhnam.dev' type='Personal Website' date='December 2022' images="/Images/HTML.png||/Images/CSS.png||/Images/react.png||/Images/Javascript.png" link='https://chinhnam.dev' content="Developed as a way to showcase my experience, projects, essays, and general progress.///Written using ReactJS on top of JSX, as well as HTML and CSS.///Long-standing and frequently changed codebase as I will update this site whenever I have new information to add (process made simple with the use of React components, such as my experience boxes and these project boxes)."/>
      <ProjectBox title='Overwatch Analysis' type='Data Science/Analysis' date='May 2022' images="/Images/Python.png||/Images/Jupyter.png||/Images/Pandas.png||/Images/Numpy.png||/Images/SKlearn.png" link='https://chinhnam-n.github.io/OverwatchAnalysis/' content="Analyzed datasets of hundreds of thousands of samples of professional Overwatch, provided by Blizzard to create an optimal hero composition for each gamemode/map to maximize the opportunity of a win.///High-dimensional dataset that includes players, teams, matches, timestamps and more.///Used fundamental data science libraries such as pandas, sklearn, numpy, matplotlib, and more, in order to graph win rates for each player, determine overall player efficiency, and find the heroes favored by the most efficient players.///Performed both linear and non-linear regressions to predict win-rates based on hero composition and map pick.///Combination of data analysis with machine learning, while also presenting a solution to a topic that is under constant review by analysts, finding that most maps had a certain composition that had a 71% chance of winning."/>
      <ProjectBox title='SeeFood' type='Android App' date='April 2022' images="/Images/Kotlin.png||/Images/AndroidStudio.png||/Images/Android.png" link='https://github.com/agolikov28/SeeFood' content="Developed Android app that allows users to track daily macros by scanning nutrition labels.///Utilized computer vision to accurately recognize digits on the label.///Included 'macro calculator' which lets users know what to aim for based on their bodily metrics as well as activity level."/>
      <ProjectBox title='Digit Recognition Algorithm' type='Machine Learning Algorithm' date='August 2021' images="/Images/Python.png||/Images/Pandas.png||/Images/Numpy.png||/Images/SKlearn.png" link='https://github.com/chinhnam-n/DigitRecognition/' content="Neural network that can classify images of hand-written digits.///Tested on a database of thousands of samples with Python using Tensorflow 2.0." />
      <ProjectBox title='College Admission Algorithm' type='Machine Learning Algorithm' date='March 2020' images="/Images/Python.png||/Images/Pandas.png||/Images/Numpy.png||/Images/TensorFlow.png||/Images/SKlearn.png" link='https://github.com/chinhnam-n/SeniorProject' content="Researched machine learning algorithm that predicts a student's chance of getting into a particular college based on their ACT, SAT, and GPA with a successful model accuracy of 98.1%.///High-dimensional dataset that includes players, teams, matches, timestamps and more.///Tested using various algorithms from basic linear regression to K-nearest-neighbors and neural networks.///Self-researched and developed using manually collected CollegeBoard data and TensorFlow 2.0 and Scikit-Learn.///Found that there was an obvious positive correlation between acceptance and scores, but candidates with perfect scores were often denied for reasons that were not visible in my data." />
      <br />
      
      <Footer />
    </div>
  )
}

export default Projects