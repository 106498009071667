import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import "./Resume.css"

const Resume = () => {
  return (
    <div>
      <Header />
      <Navbar />

      <h3>My Resume</h3>
      <p>Download and share my resume <a href="/Documents/ChinhnamNguyenResume.pdf" download>here</a>. If you have any questions feel free to <a href = "mailto: cn.2149@gmail.com">email</a> me.</p>
      <hr className='ContactSplit'/>
      <object data="/Documents/ChinhnamNguyenResume.pdf" type="application/pdf">
        <p className='PDFError'>
          Your browser does not support PDF files. <br />
          <a href="/Documents/ChinhnamNguyenResume.pdf">Download my resume instead!</a>
        </p>
      </object>
      <br /><br />

      <Footer />
    </div>
  )
}

export default Resume