import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import "./ExperienceBox.css"

const ExperienceBox = (props) => {
    const [open, setOpen] = useState(false)

    let items = props.content.split("///")
    const listItems = items.map((item) =>
        <li>{item}</li>
    );

    return (
        <div>
            {/*********** Header Box **************/}
            <button type="button" onClick={() => setOpen(!open)} className='header'>
                {open? <div className='Arrow'><FontAwesomeIcon icon={faCaretDown} size="xl" /></div> : <div className='Arrow'><FontAwesomeIcon icon={faCaretRight} size="xl" /></div>}
                <div className='ImageBox'>
                    <img src={props.image} className='LogoBox'></img>
                </div>
                <div className='Text'>
                    <h3 className='ExperienceBoxTitle'>{props.title}</h3>
                    <i className='ExperienceBoxCompany'>{props.company}</i>
                    <i className='Date'>{props.date}</i>
                </div>
            </button>

            {/************** Work Info ******************/}
            {open &&
            <div className='ExperienceContentBox'>
                <h4 className='ExperienceContentHeader'>{props.team} | {props.location}</h4>
                <ul className='ExperienceContentText'>{listItems}</ul>
            </div>}
        </div>
    )
}

export default ExperienceBox