import React, { useState } from 'react'
import Navbar from "../../Navbar/Navbar"
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import "./ParisTexas.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ParisTexas = () => {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <Header />
            <Navbar />

            <div className='HeaderImgDiv'>
                <img className='HeaderImg' src='/Images/ParisTexas1.jpeg' alt='Paris, Texas'/>
            </div>
            <h2 className='EssayTitle'><i className='FilmTitle'>'<i className="InnerTitleText">Paris, Texas</i>'</i> and the Desire to be Somewhere Else</h2>
            
            <div className='EssayContent'>
                <p>
                <span class="first-letter">A</span>few weeks ago, I found myself traveling through three distinct cities, each far removed from anywhere I have ever lived. There I felt as though I could portray myself as someone I really wanted to be. Shielded by the veil of tourism that I knew would lift upon my departure, I felt protected. Every step I took was one I had never taken before. Upon my return home, I was struck with a depressive period, losing my motivation to return to my normal life as I frequently fantasized about running away to a new, foreign adventure. I relapsed into old habits that I never wanted to touch again and felt as though my very being was a building made of sand. Time, however, heals all wounds (or so they say), and eventually I found myself back to my normal-ish routine. As I reflected on my time abroad as well as the following low period, I realized that what I yearned for was not a change of scenery, but to again become who I was in those beautiful, mysterious places: adventurous, contemplative, and introspective. Instead, I traversed through time feeling dull and unmotivated, as I occupied the bulk of my day looking at the ground like a puppet with no master.
                </p>
                <br />

                <p>
                Confucius is attributed with the saying: “Wherever you go, there you are.” Taken literally, this may seem like a trivial concept, which may also explain why this quote is so commonly misunderstood. Figuratively, it serves as commentary on how our perceptions of ourselves play a role in our growth and experiences as people. Regardless of where you are in life, both physically and metaphorically, if you are displeased with yourself then you will remain displeased with yourself until some force, either internal or external, electrocutes you into change. Wim Wenders’s masterpiece <i className="InnerTitleText">Paris, Texas</i> (1984) explores this same idea through the mysterious trauma that fuels Travis’s disappearance, and his eventual redemption. While redemption through righting past mistakes is not a theme unique to the film, especially in “western” and “road” films, Wenders approaches it in a manner rare to be found for his era: Travis’s success comes in the form of a substantial sacrifice. His redemption is found within his efforts in reconnecting his son with his mother, but also realizing that he can no longer be a part of their life no matter how badly he desires it. His story won him not a path back to the life he lived and the person he once was, but instead a chance to build a better life for himself: as a changed man.
                </p>
                <br />

                <p>
                The film opens with a rugged man, soon to be revealed as Travis, played by Harry Dean Stanton, feebly walking across the desert. Malnourished and severely dehydrated, he stumbles into a decrepit bar where he gorges himself on ice, the only source of water, before passing out. Over the course of the film, Travis reunites with his brother, Walt, his sister-in-law, and his son, Hunter, who he has abandoned for four years. He refuses to explain his disappearance until he decides to try to find his estranged wife Jane, who also disappeared. Consequently, Travis and Hunter embark on a journey that forces them to confront their shared past, present, and eventually separated future.
                </p>
                <br />

                <div className='InnerImgDiv'>
                    <img className='InnerImg' src='/Images/ParisTexas5.jpeg' alt='Travis emerges from the desert'/>
                    <p className='InnerImgCaption'><i>Coming back to reality</i></p>
                </div>

                <p>
                As the story unfolds, the viewer is not only given glimpses into Travis’s former life, but also a greater understanding of him as a whole. At the beginning of the film, Travis appears disoriented, fearful, and entirely mute. Even when he is picked up by Walt, whom he recognizes, he runs away multiple times before he’s even able to speak to him. His eyes lack any form of attention and reason does not belong. However, the viewer understands that Travis isn’t insane or really even a bad man; he is simply just lost. As a result, Travis is immediately established as our “hero” of the story and the one that will garner most of our support. It’s not until Walt shows him footage taken from a family trip when Travis begins to act like his familiar self, producing a brilliant scene of juxtaposition between Travis’s past life and his present self.
                </p>
                <br />

                <p>
                On Travis’s third day staying with his brother’s family as well as Hunter, Walt decides it’s time to try to jumpstart his memory. He pulls out an old Super 8 film and the family gathers around to watch. On the screen, the viewer sees old, spotty footage of the group, as well as Jane, going on a family road trip. Spirits are high and it’s clear that both families are truly happy, as they play on the beach and joyfully embrace each other. The footage is even powerful enough to make Travis visibly happy, at least for a brief moment. Upon reliving these memories, Travis is inspired to return to his past life, seeing how happy everyone was when they were all together. It’s also important to note that this is the first time we see present-day Travis and Hunter genuinely interact. Hunter even acknowledges Travis as his true father seeing that he offers him a “goodnight dad” before going to sleep. This marks Travis’s first step in redemption: he decides that he wants to, and is capable of, being Hunter’s father again and rebuilding his broken family. Inspired, or perhaps misled, by the footage’s romantic nostalgia, Travis comes face-to-face with what he believes to be his ultimate desire: returning to who he once was, as he imagines that that was who he was meant to be.
                </p>
                <br />

                <div className='InnerImgDiv'>
                    <img className='InnerImg' src='/Images/ParisTexas2.jpeg' alt='Travis watches the Super 8 film'/>
                    <p className='InnerImgCaption'><i>Super 8 film</i></p>
                </div>

                <p>
                Upon making up his mind, he immediately sets out to achieve his faux-redemption. He enquires Carmelita, the housemaid, for her help in transforming himself into a father. She takes him upstairs and after a bit of deliberation asks him the real question: “Do you want to want to be a rich father or a poor father?” Travis chooses somewhere in between, to which Carmelita corrects him by arguing that there is no inbetween. Travis decides to be a rich father and struts around in his new clothes with perfect posture and his head held high. Here, we can see that Travis’s “change” is entirely cosmetic. While Travis believes that he can reclaim his role as a father, we know that looking the part means very little to actually playing it. It’s this second step into the facade that drives him further into the story: a false hope that he can be who he wants to be just by changing how he looks. It’s important to note, however, that Wenders doesn’t bring Travis down to reality immediately, as he allows Hunter to play along with Travis’s fantasy and permits him to fall deeper into his misguided delusions. He allows him to taste hope, a feeling he likely hasn’t felt since he disappeared, and see all that it stands for. He is so close to everything he feels he wants, and that is when Wenders gives him a moment of clarity.
                </p>
                <br />

                <p>
                Travis and Hunter embark on their journey to find Jane, which leads them to follow her to a peep-show club. As Travis follows her in, it’s clear that he is highly uncomfortable. He makes his way to the top floor and recognizes the back of a blonde woman, clearly Jane, sitting at a bar. Before he’s able to approach her or even see her face, he’s ushered down to the peep show section of the club. In the first phone booth scene, Travis and Jane interact for the first time through a one-sided window. Thinking him to be any ordinary customer, they begin to engage in a beautifully melodic conversation that reflects the highs and lows of their former, yet painfully unforgotten, relationship. At first, Travis is apprehensive, refusing to speak or respond to her questions until she offers to take off her sweater, to which he affirms her to keep it on. Then, Travis finds himself in the limelight:
                </p>

                <div className='Quote'>
                    <p className='QuoteText'><b>Jane &nbsp;&nbsp;:</b> I’m sorry ... I just don’t know exactly what it is you want.<br />
                                             <b>Travis :</b> I don’t want anything.<br />
                                             <b>Jane   &nbsp;&nbsp;:</b> Well, why’d you come here, then?<br />
                                             Travis pauses and thinks.<br />
                                             <b>Travis :</b> I want to talk to you.</p>
                </div>

                <p>
                For the first time in four years, both Travis and Jane are given the opportunity to try and understand each other. However, when Jane tries to get him to open up, Travis maintains his facade and deflects instead to her work. She reveals to him that she spends most of her time listening rather than actually talking. Not convinced, Travis pushes further, so absolutely certain that she extends more to her customers than she lets on. Travis begins to interrogate Jane in a moment that reflects his inner jealousy and rage, a stark contradiction to the kind and reserved man we’ve come to know throughout the film. Jane, uncomfortable now, gets up to leave while Travis, recognizing his mistake, begs her to stay. She relents and sits back down. Staring directly into the camera, she says that she’ll listen to him and that she doesn’t mind it as that’s what she does “all the time”, not knowing that Travis has already gone. In this first encounter, we’re able to not only experience Jane for the first time, but also their intricate dynamic. Jane is lively, young, and cheerful, and Travis’s tired eyes light up when he sees her. He still cares for her and wants nothing more than to express that love, but his jealousy and anger quickly take hold as his imagination dictates his perception of the place where she works. In a final, gnashing reflection of his past self, Travis takes off and brings Hunter to a bar where he gets drunk and leaves his son with the responsibility of helping him back to the hotel.
                </p>
                <br />

                <p>
                Here, we are introduced to a much more frightening version of Travis as we come to realize that we may not know him as well as we think. Suddenly, Travis’s moral stage begins to crumble beneath him as he teeters on the edge, potentially plummeting not towards a future with his wife and son, but the same past that left the three of them scarred and separated. In the second, and final, phone booth scene, Travis returns to the peep-show club to confront Jane once again. In a beautiful monologue, Travis recounts their failed relationship from a third person point of view: they were madly in love and had big dreams for Hunter, but Travis’s jealousy turned him into a hateful alcoholic, leading to their trailer going up in flames and sparking both of their eventual disappearances. Soon, Jane is able to recognize the identity of the man on the other side of the glass and they work together to shine light back through the one-way mirror. For the first time in the film, the two are finally able to truly look at each other. Jane wants to be with him but Travis relents as he has decided he cannot be a part of their lives, no matter how badly he wishes to be. His short relapse into his old damaged self forced him to challenge the false hope that has been his driving motivation and also recognize that if there’s a way to fix the bonds he has broken, there’s only one thing he can do. He tells Jane where to find Hunter and then leaves, but not before ensuring he’s able to see the two together first. In these final moments, Travis is redeemed. For the sake of his child and wife, he realized what he must do and while it hurt him more than anything he knew that there could be no other way. Through this crucible he is reborn and given a new life and a new opportunity to become someone better. But, in doing so, his sacrifice has come in the form of leaving behind the two people he cares about the most.
                </p>
                <br />

                <div className='InnerImgDiv'>
                    <img className='InnerImg' src='/Images/ParisTexas4.jpeg' alt='Travis recounts their story'/>
                    <p className='InnerImgCaption'><i>Travis recounts their story</i></p>
                </div>
                
                <p>
                Like most people, Travis has made a mistake that he can never undo. He spirals further and further but in a moment of grace he’s able to realize not a solution, but a path for hope. Wenders succeeds by creating a character so deeply flawed and placing him in a unique story that makes each step of his journey as visceral as can be, even cementing the final phone booth scene as one of the most revered scenes of all time. As a deeply layered character study, the viewer not only continues to root for Travis even after his secret is revealed, but they feel even closer to him. They are able to recognize Travis as a real person, full of flaws and past trauma, fighting for what he wants in life. The payoff comes when Travis, in order to move forward, has to forfeit the hope that was inadvertently holding him back, a notion that I believe is a quintessential mechanism in life.
                </p>
                <br />

                <div className='InnerImgDiv'>
                    <img className='InnerImg' src='/Images/ParisTexas6.jpeg' alt='Travis watches on'/>
                    <p className='InnerImgCaption'><i>Redemption</i></p>
                </div>

                <p>
                Similar to Travis, my depressive period led me down a darker path than I ever expected. It wasn’t until a few weeks later, possibly with the help of writing this essay, that I was able to begin to grasp a glimpse of the underlying issue. Faced with the normalcy of reality, I was frightened that I could no longer be the same person I was while on my trip. I wanted so badly to live as originally and wholly as I could, that I couldn’t imagine achieving the same effect back at home. I know now that my physical surroundings have little effect on my ability to experience life the way I wanted to, and that my beliefs and the way I approach everyday life plays a far greater role. Perhaps I was never even meant to be the same person I was before. Perhaps all you are is who you choose to be at that exact moment.
                </p>
                <br />

                <div className='Signature'>
                    <p>By Chinhnam Nguyen</p>
                    <p>June 13th, 2023</p>
                </div>

                
            </div>

            <hr className='Splitter'/>

            <div className='Download'>
                <a href="/Documents/Paris, Texas and the Desire to be Somewhere Else.pdf" download className='DownloadButton'>
                    <h3 className='DownloadText'>Download PDF <FontAwesomeIcon icon='download'></FontAwesomeIcon></h3>
                </a>
            </div>
            

            <Footer />
        </div>
    )
}

export default ParisTexas