import React, { useState } from 'react'
import Navbar from "../../Navbar/Navbar"
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import "./EEAAO.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EEAAO = () => {
      const [open, setOpen] = useState(false)

return (
    <div>
        <Header />
        <Navbar />

        <div className='HeaderImgDiv'>
            <img className='HeaderImg' src='/Images/EEAAO.png' alt='<i>Everything Everywhere All At Once</i>'/>
        </div>
        <h2 className='EssayTitle'>Challenging Gender Roles: How <i className='FilmTitle'>'<i className="InnerTitleText"><i>Everything Everywhere All At Once</i></i>'</i> Inspires the Courage to Love</h2>
          <div className='EssayContent'>

            <div className='Quote'>
              <p className='QuoteText'><b>Gong Gong :</b>&nbsp;  [to Evelyn]&nbsp;  If you abandon this family for that silly boy, then we will abandon you.</p>
            </div>

            <p>
            <span class="first-letter">T</span>hat silly boy in question is Waymond Wang; sweet, timid, child-like, indeed silly, and Evelyn's soon to be husband. Obsessed with placing googly eyes everywhere for his own amusement, the audience takes quickly to Waymond's goofy, nonconfrontational manner, believing him to be an unserious and unmasculine husband. Even though Waymond may be a prime example of a “beta male” character, he expresses his masculinity in his own manner, choosing to approach conflicts and situations with love and kindness rather than dominance and violence. As a result, his subversion of the masculine norm shows that traditionally masculine traits are not the only way to achieve masculine values.
            </p>
            <br />

            <p>
            As a word, masculinity and toxic masculinity in particular has been the main talking point of societal male-centric debate all over the world. As an idea, every male has compared his actions and habits with the ideal mockups that are put on display in the media. At a young age, men are either knowingly or unknowingly taught to be independent, assertive, stoic, and above all, strong, for without these things they are no men at all (Priyadarshini & Díaz, 2019). This idea is sustained by the innate human habit to value how others perceive oneself, as argued by Starr, since such impressions help to define the “interactions between people, enabling us to know in advance what is expected of us and what we can expect from others” (Starr, 1984). These traits aren't without purpose, however, as it's believed that masculine men served their society by acting as protectors as well as breadwinners (Starr, 1984). All in all, men are conditioned to believe that not only their success, but societal value, comes with masculinity. This definition is not one that only exists within America, as all over the world men fall victim to the belief that they must constantly need to dominate.
            </p>
            <br />

            <p>
            Chinese beliefs towards masculinity have been perpetuated throughout its rich history. Similar to American masculinity, Chinese society dictates that men ought to be driven and heroic, as they should value “physical strength over emotional expression” (Zhang, 2021). In fact, during the 2020 Chinese People's Political Consultative Conference, committee member Si Zefu actually criticized Chinese male teenagers for being too “feminine.” This resulted in the Ministry of Education introducing a new emphasis on physical education as well as research into the influence of popular culture on male habits (Zhang, 2021). One area in which the two societies differ on the male role, however, is how he should contribute to society. In America, men are taught to focus on providing for their family before anything else. In China, the sentiment is more collective as males are valued as a group that serves their community. It's believed that these differences stem from other societal factors, such as America's reliance on capitalist democracy compared to China's communist authoritarianism (Zhang, 2021). Regardless, it's clear that masculinity, though not identical in every region, reflects common beliefs that are shared throughout the world.
            </p>
            <br />

            <p>
            Waymond's subversion of masculine norms is clearly set to challenge the standards that have been cultivated in both Chinese and American culture, as both are represented within the movie. These principles are commonly perpetuated through media such as foreign and domestic films, and perhaps surprisingly most often by films of the same genre that <i>Everything Everywhere All At Once</i> belongs to. It's important, then, to look at these films to understand more deeply what societies around the world believe regarding masculinity and how men should behave.            </p>
            <br />

            <div className='InnerImgDiv'>
                <img className='InnerImg' src='/Images/TheGoodTheBadTheUgly.png' alt='The Good, the Bad and the Ugly'/>
                <p className='InnerImgCaption'><i>The Good, the Bad and the Ugly</i> (1966) dir. Sergio Leone</p>
            </div>

            <p>
            It's no secret that the film industry is one of the largest contributors to the world economy and it's also no secret why. Films allow people to experience different parts, versions, and subversions of global society, and as a result, they are inadvertently learning more about themselves and the world around them. It's beneficial to view world cinema as a web of information that connects different regions and demographics of the world. From Hollywood to Bollywood, arthouse British to arthouse Hongkongers, it becomes evidently clear that world cinema itself is a Petri dish of films and ideas that are constantly expressing themselves either towards or against another ideal, each fighting to make their own case about the world in which they take place. This push and pull has led to the variety and diversity of films that we know today, and is also integral to the success of these films. This diversity represents all the different ideas, cultures, and locations that these films are produced with, but just like real life, this diversity also leads to different regions and sectors within the web, each with their own distinctions.
            </p>
            <br />

            <p>
            In a neighborhood in central Los Angeles, California, the American, and in some ways the global, film industry was born. Originating from a short series of photographs depicting a running horse, Hollywood is the world's oldest and de facto national film industry, producing over 400 films and grossing $21.3 billion dollars in 2021 alone (McClintock, 2022). Due to their quick start and dominance over the film industry, Hollywood's productions have had an incredible impact on global society and American culture in particular. Through huge, action-packed, blockbuster productions, its films have been known to highlight capitalism and democracy as well as the so-called “American Dream,” the idea that anyone can achieve their dreams given that they work hard enough (Jenkins, 2022). From works such as Dr. Strangelove (1964) to Modern Times (1936), Hollywood films have reflected the society in which they were produced, telling us stories of psychotic U.S. Air Force Generals to illuminate American fears towards weapons of mass destruction, and hapless factory workers to warn against the speed with which the country was modernizing (Rollins, 2002). While these films focused on the economic and global impact of national developments, other productions chose to center on more intimate aspects of society and the interactions between its members.
            </p>
            <br />

            <p>
            In Martin Scorsese's magnum opus Taxi Driver (1976), Scorsese highlights the dangers of toxic masculinity years before the phrase was first coined. In it, cabbie Travis Bickle's loneliness overtakes his sanity as he falls deeper into fantasy and faux-heroism. As he recoils further and further away from society, apathy begins to fade and violence and disgust takes over, leading him to a rage-fueled massacre while believing himself to be the sole hero. Scorsese's work reflected American views on masculinity, as he encouraged viewers to identify with Travis while proceeding to have him frequent pornography theaters and treat women in an exploitative manner (Oh, 2020). Through Travis, Scorsese forces the American man to reconcile their beliefs with a depiction of a man who disguises his hate and insecurity with counterfeit strength, confidence, and bravery.
            </p>
            <br />

            <div className='InnerImgDiv'>
                <img className='InnerImg' src='/Images/JackieChan.png' alt='Jackie Chan'/>
                <p className='InnerImgCaption'><i>The Big Brawl</i> (1980) dir. Robert Clouse</p>
            </div>

            <p>
            The Chinese film industry did not follow a similar path as their American counterparts, and it would actually be difficult to talk about the Chinese film industry without mainly focusing on works contributed by Hong Kong. While American films grew into more lavish, action-packed multi-million dollar megahits, Hong Kongese films succeeded in the realm of intimacy and abstraction, building upon the works of European filmmakers in a genre known as “arthouse” (Morten, 2003). Where American films were chronological and clear cut, Hong Kongese films weren't afraid to twist and turn through time. Where Hollywood had one distinct shot composition style, Hong Kongese films valued variety over standard techniques, viewing the camera as more than a medium through which to tell the story (Morten, 2003). These differences are what allowed each industry to flourish within their own spaces, while adding diversity and variation to a burgeoning global media landscape.
            </p>
            <br />

            <p>
            Perhaps one of the most influential actors of all time, and certainly the most impactful Hong Kongese actor ever, Jackie Chan produced over a hundred films while performing his own martial arts stunts for each one. His films included some of the best action films ever produced as well as inspired children all over the world to study martial arts. As Jackie served as the main character in most of these films, his name became synonymous with the “leading man” role in Hong Kong as he motivated his own portrayal of masculinity. While most of his roles did involve fighting and physical prowess, his characters depicted a sense of emotion that most American leading men did not, and even though his characters did spend a lot of time fighting, it would only be after he's cornered by a hundred men. Masculinity, according to Jackie, was the ability to fight, but also the sensibility to know when not to, and the mental fortitude to not hide one's emotions, but to control them (Shi, 2020). This difference in understanding between Chinese and American masculinity is an example of the varying regions of the web of information that is created by world cinema.
            </p>
            <br />

            <p>
            In the broadest sense, the spread of information through world cinema has helped shed light on some of the most pressing issues in global society. Through the decades, filmmakers all over the world used their work to advocate for human rights, social justice, as well as world peace. Now, with the topic of masculinity at the forefront, a new wave of films have begun to focus on masculinity and experiment with the plasticity of its definition. This new genre of male exploration seeks to answer what it truly means to be a man, with the latest addition being the Daniels' <i>Everything Everywhere All At Once</i> (2022).
            </p>
            <br />

            <div className='InnerImgDiv'>
                <img className='InnerImg' src='/Images/EEAAO2.jpeg' alt='Everything Everywhere All At Once'/>
                <p className='InnerImgCaption'>The family of <i>Everything Everywhere All At Once</i></p>
            </div>

            <p>
            <i>Everything Everywhere All At Once</i> is described as an “American absurdist comedy-drama film” which is pretty much entirely true. The story revolves around Evelyn, played by Michelle Yeoh, a Chinese-American who finds her world falling apart with her growing age—her laundry business is being investigated for tax evasion, she is having to look after her elderly father, her husband, Waymond, played by Ke Huy Quan, is filing for a divorce, and most importantly, her daughter is growing more distant from her (Roy, 2022). As she struggles to find a balance between every facet of her life, she finds herself pulled into a multiversal war that forces her to come to terms with herself and her family. While the film emphatically discusses a myriad of ideas, I will primarily focus on Waymond and his portrayal of masculinity, and how he teaches us that there's more than one way to be a man.
            </p>
            <br />

            <p>
            Dorky, fun-loving, and with his head in the clouds, Waymond serves as the film's comedic relief for the majority of its runtime. He is introduced almost immediately in the film trying, and failing, to do something that obviously brings him a lot of pain: serve divorce papers to his wife. His nonconfrontational manner is corroborated by shots of him dancing with customers and rhythmically playing with the front desk bell to Evelyn's dismay. All in all, the viewer quickly classifies Waymond as everything a typical masculine man is not, which is true! He is by no means a counterpart of James Bond or Superman, nor is he meant to be. For example, where a masculine man would demand more time to get his taxes in order, Waymond relents and graciously accepts the extra time, knowing that Deirdre, the IRS inspector, was already past her breaking point. Another example is seen through the laundromat itself. While a traditional masculine man would be in charge of the business and dealing with customers, Waymond has no issue sitting back and letting Evelyn maintain the business as she wishes. Regarding Evelyn's struggling relationship with Joy, Waymond regularly acts as the mediator between the two, trying to get both to understand the other's perspective. If these depictions didn't make the differences obvious enough, we actually get a portrayal of what a traditionally masculine Waymond would look like through the characters of Alpha Waymond and CEO Waymond, using the help of the multiverse.
            </p>
            <br />

            <div className='InnerImgDiv'>
                <img className='InnerImg' src='/Images/EEAAO3.png' alt='Alpha Waymond'/>
                <p className='InnerImgCaption'><i>Alpha Waymond relents to Evelyn</i></p>
            </div>

            <p>
            Alpha Waymond is introduced as the mastermind behind the search for the “true” Evelyn. He's butch, confident, assertive, and again most importantly, strong. When he first appears in the elevator on the way to the IRS meeting, Evelyn actually shows that she not only is shocked by this version of him, but in fact she prefers it. This is seen through her reaction to the way he controls the conversation and isn't afraid to touch her to get his point across. Another significant difference is that Alpha Waymond doesn't shy away from confrontation. When threatened by the police, he doesn't back down or run away. He stands his ground and fights them head on, in one of the best choreographed martial arts fight sequences of the decade. Regular Waymond, however, is more partial to his “flight” rather than “fight,” as he chooses to hide rather than attack, since he knows he doesn't have the skills that Alpha Waymond possesses. While Alpha Waymond depicts a more assertive and direct confidence, CEO Waymond appeals towards the material success aspect of traditional masculinity. CEO Waymond is more subtly confident and portrays his success through his clothing and demeanor. While speaking with Evelyn, he is calm and stoic, rarely showing emotion until the last moment where he relents he still wishes he had a life with her, regardless of the material success that they find. Both of these depictions succeed in highlighting not only the differences between them and Regular Waymond, but also the good that his normal characterization produces.
            </p>
            <br />

            <p>
            In the most pivotal scene in the movie, where everything seems lost and Waymond and Evelyn have their back against the wall, he reveals what he's been holding inside. In a monologue where it could be argued that he's talking more so to the audience than the friends and enemies around him, he admits that he's aware of how everyone thinks of him–beta, childish, and too kind.


            <div className='Quote'>
              <p className='QuoteText'><b>Waymond Wang :</b>&nbsp;  [to Alternate Evelyn]&nbsp;  You think I'm weak don't you? All of those years ago when we first fell in love... your father would say I was too sweet for my own good. Maybe he was right.</p>
            </div>
          
            He's known all this time how people see and treat him, and he's understood. For the first time in the movie, Waymond is portrayed as standing up for himself in a moment that is borderline heroic,  though in reality he's been standing up for himself his entire life.

            <div className='Quote'>
              <p className='QuoteText'><b>Waymond Wang :</b> You think because I'm kind that it means I'm naive, and maybe I am. It's strategic and necessary.</p>
            </div>

            Instead of choosing to approach situations the way Alpha Waymond or CEO Waymond would, he chooses to embrace kindness and patience, in a world where violence seems to be the prevailing method.

            <div className='Quote'>
              <p className='QuoteText'><b>Waymond Wang :</b> I know you see yourself as a fighter. Well, I see myself as one too. This is how I fight.</p>
            </div>

            Waymond has always been a fighter and we can see that in the scenes that originally tricked us into thinking the opposite. During the IRS meeting, viewers mostly recognize Waymond as acquiescing to Deirdre, when in reality he is constantly working to mitigate the situation while coming up with excuses for his wife. In fact, without Waymond's quick thinking, Deirdre never would have offered them extra time in the first place. He acknowledges there are issues with his marriage and instead of running away from those issues, he takes active steps to resolve them through bringing up the discussion of divorce with Evelyn in a manner that is true to him. Here, the film plays into the masculine idea that if a man isn't physically fighting or vocally demanding something they must not really want it, when in reality that is not the case.
            </p>
            <br />

            <p>
            Regular Waymond's take on masculinity shows us that there is more than one way to be a man. Throughout the film, he is able to fight for what he believes in without having to resort to physical or emotional violence. He recognizes the oppressive nature of the world and instead of raging against it, he actively chooses to push back with love and patience, refusing to give in to the brutality and apathy around him. The film even goes as far as to show the ugly truth within the other, more traditionally masculine depictions of Waymond, as although the audience is first enamored by Alpha Waymond, he's later revealed to be aggressive, unsympathetic, and impatient. He builds her up, telling her that she's the one he's looking for, and then abandons her at the first hint of that not being the case. As for CEO Waymond, even though he represents the pinnacle of success, the audience perceives him as more tragic than anything. Though he has material wealth, he'll never have the life he yearns for: laundry and taxes with Evelyn. It is later, through Evelyn's epiphany, that she finally understands her own Waymond, the man that she and everyone else around her has always thought to be weak and childish.
            </p>
            <br />

            <div className='InnerImgDiv'>
                <img className='InnerImg' src='/Images/EEAAO4.jpeg' alt='Alpha Waymond and Evelyn'/>
                <p className='InnerImgCaption'><i>In another life...</i></p>
            </div>

            <p>
            <i>Everything Everywhere All At Once</i> took the world by storm, quickly rising to the top of awards lists and people's hearts. It's clear that each of the characters resonated with audiences around the world, but Waymond in particular was especially loved. In the global sense, the film incorporated elements from different genres, regions, styles, and filmmakers, all to tell a story that people could relate to. It shed light on the discussion of masculinity by flipping traditional masculinity on its head, as the Daniels were able to create a hero out of the most unsuspecting of male leads. It offers a new take on the role and definition of masculinity and as a result, Waymond serves as a beacon of hope—that though there will always be struggle, we all have the ability to fight back in our own ways, regardless of the norms set by society.
            </p>
            <br />

            <div className='Signature'>
                <p>By Chinhnam Nguyen</p>
                <p>December 10, 2022</p>
            </div>

            
        </div>

        <hr className='Splitter'/>

        <div className='Bibliography'>
            <h3 type="button" onClick={() => setOpen(!open)} className="BibliographyButton">
                {open? <div className='BibButtonText'>Bibliography <FontAwesomeIcon icon='arrow-down'></FontAwesomeIcon></div> : <div className='BibButtonText'>Bibliography <FontAwesomeIcon icon='arrow-right'></FontAwesomeIcon></div>}
            </h3>

            {open &&
            <div className='BibliographyText'>
                <p>
                Jenkins, A. P. (2022, March 27). Why is film important to society. brilliantio. Retrieved December 15, 2022, from https://brilliantio.com/why-is-film-important-to-society/
                </p>
                
                <p>
                McClintock, P. (2022, March 14). 2021 global box office down 50 percent from pre-pandemic times: MPA report. The Hollywood Reporter. Retrieved December 15, 2022, from https://www.hollywoodreporter.com/movies/movie-news/2021-global-box-office-pandemic-1235110511/
                </p>

                <p>
                Morten, L. (2003, April 26). What is Hong Kong Cinema? What is Hong Kong cinema. Retrieved December 15, 2022, from https://www.lisamorton.com/WHAT%20IS%20HONG%20KONG%20CINEMA.htm
                </p>

                <p>
                Oh, S. (2020, September 23). The masculine oversight of 'taxi driver'. Film School Rejects. Retrieved December 15, 2022, from https://filmschoolrejects.com/taxi-driver/
                </p>

                <p>
                Priyadarshini, A., & Díaz, M. A. (2019, August 20). Masculinity and femininity. Voices of Youth. Retrieved December 15, 2022, from https://www.voicesofyouth.org/blog/masculinity-and-femininity    
                </p>

                <p>
                Rollins, P. C. (2002). Hollywood as historian: American film in a cultural context. Univ. Press of Kentucky.    
                </p>

                <p>
                Roy, S. S. (2022, May 24). 'Everything everywhere all at once' ending, explained - how did evelyn stop Jobu Tupaki?: DMT. Digital Mafia Talkies. Retrieved December 15, 2022, from https://dmtalkies.com/everything-everywhere-all-at-once-ending-explained-2022-film/    
                </p>

                <p>
                Shi, B. (2020). Jackie Chan: Redefining What It Means to be a Man in Film (dissertation). Swarthmore College.    
                </p>

                <p>
                Starr, M. E. (1984). The Marlboro man: Cigarette smoking and masculinity in america. Journal of Popular Culture, 17(Oh, 2020), 45. Retrieved from https://www.proquest.com/scholarly-journals/marlboro-man-cigarette-smoking-masculinity/docview/1297351702/se-2    
                </p>

                <p>
                Zhang, Z. (2021, October 18). The history of masculinity in China. The Footnote. Retrieved December 15, 2022, from https://the-footnote.org/2021/10/18/the-history-of-masculinity-in-china/    
                </p>
            </div>}
        </div>

        <div className='Download'>
            <a href="/Documents/Challenging Gender Roles How “Everything Everywhere All At Once” Inspires the Courage to Love.pdf" download className='DownloadButton'>
                <h3 className='DownloadText'>Download PDF <FontAwesomeIcon icon='download'></FontAwesomeIcon></h3>
            </a>
        </div>
        

        <Footer />
    </div>
  )
}

export default EEAAO